/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CardIcon from 'components/Card/CardIcon.jsx';
import Assignment from '@material-ui/icons/Assignment';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
// import Table from 'components/Table/Table.jsx';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import purple from '@material-ui/core/colors/purple';
import Edit from '@material-ui/icons/Edit';
import CustomTabs from 'components/CustomTabs/CustomTabs.jsx';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import AppInstalledIcon from '../../assets/icons/AppDownload 2.svg';
import firebase from '../../config/config';
import MaskedTruckerListView from './Masking/MaskedTruckersListView';

const NameTableRowStyle = { display: 'flex', alignItems: 'center' };
const db = firebase.firestore();

class Trucker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      truckersData: [],
      pendingTruckers: [],
      rejectedTruckers: [],
      approvedTruckers: [],
      isLoading: false,
    };
    this.getTable = this.getTable.bind(this);
    this.getTruckersData = this.getTruckersData.bind(this);
    this.AddTrucker = this.AddTrucker.bind(this);
  }

  componentDidMount() {}

  handleChange = trucker => event => {
    db.collection('Truckers')
      .doc(trucker.truckerId)
      .update({
        isActive: event.target.checked,
      });
  };

  /**
   * Will return the Tabs ATH and BTH
   * @function
   */
  getTabs() {
    // const { approvedTruckers, pendingTruckers, rejectedTruckers } = this.state;
    return (
      <CustomTabs
        headerColor="#fff"
        tabs={[
          {
            tabName: `Approved`,
            tabContent: this.getTable('approved'),
          },
        ]}
      />
    );
  }

  /**
   * Will Get ALl the Truckers For The User
   * @function
   */
  getTruckersData(branches) {
    const { classes, activeRole } = this.props;
    const { truckersData } = this.state;
    const colors = ['info', 'success', 'danger'];
    const truckerRef = db.collection('Truckers').orderBy('name', 'asc');
    const truckers = truckersData;
    const maskedNumber = number => `******${String(number).slice(-4)}`;
    this.setState({ isLoading: false });
    truckerRef.onSnapshot(truckerData => {
      const pendingTruckers = [];
      const approvedTruckers = [];
      const rejectedTruckers = [];
      this.setState({ isLoading: false });
      let flag = 0;
      truckerData.forEach(trucker => {
        if (
          trucker.data().updatedByRole !== 'premiumTransporter' &&
          activeRole === 'sales' &&
          activeRole === 'traffic'
        ) {
          if (trucker.data().status === 'pending') {
            pendingTruckers.push({
              color: colors[0],
              data: [trucker.data().name, trucker.data().contactPerson],
            });
          }
          if (trucker.data().status === 'approved') {
            approvedTruckers.push({
              color: colors[1],
              data: [
                <div style={NameTableRowStyle}>
                  {trucker.data().blacklist ? (
                    <del>{trucker.data().name}</del>
                  ) : (
                    <p style={{ margin: 0 }}>{trucker.data().name}</p>
                  )}
                  {trucker.data().isAppLoggedIn ? (
                    <img
                      src={AppInstalledIcon}
                      alt="icon"
                      style={{ width: '14px', margin: '10px' }}
                    />
                  ) : (
                    ''
                  )}
                </div>,
                trucker.data().blacklist ? (
                  <del>{trucker.data().contactPerson}</del>
                ) : (
                  trucker.data().contactPerson
                ),
                trucker.data().blacklist ? (
                  <del>{maskedNumber(trucker.data().phoneNumber)}</del>
                ) : (
                  maskedNumber(trucker.data().phoneNumber)
                ),
                <Button
                  color="warning"
                  className={classes.actionButton}
                  key={trucker.data().truckerId}
                  onClick={() => this.openEditForm(trucker.data())}
                  justIcon
                >
                  <Edit className={classes.icon} />
                </Button>,
              ],
            });
          }
          if (trucker.data().status === 'rejected') {
            rejectedTruckers.push({
              color: colors[2],
              data: [trucker.data().name, trucker.data().contactPerson],
            });
          }
          const truckerResult = trucker.data();
          truckers.push({
            color: colors[flag],
            data: [truckerResult.name, truckerResult.contactPerson],
          });
          if (flag === 0) {
            flag = 1;
          } else {
            flag = 0;
          }
        } else if (trucker.data().updatedByRole !== 'premiumTransporter') {
          if (trucker.data().status === 'pending') {
            pendingTruckers.push({
              color: colors[0],
              data: [
                trucker.data().name,
                trucker.data().phoneNumber,
                trucker.data().email,
                trucker.data().contactPerson,
              ],
            });
          }
          if (trucker.data().status === 'approved') {
            approvedTruckers.push({
              color: colors[1],
              data: [
                // trucker.data().name,
                <div style={NameTableRowStyle}>
                  <p style={{ margin: 0 }}>{trucker.data().name}</p>
                  {trucker.data().isAppLoggedIn ? (
                    <img
                      src={AppInstalledIcon}
                      alt="icon"
                      style={{ width: '14px', margin: '10px' }}
                    />
                  ) : (
                    ''
                  )}
                </div>,
                maskedNumber(trucker.data().phoneNumber),
                trucker.data().email,
                trucker.data().contactPerson,
                <Button
                  color="warning"
                  className={classes.actionButton}
                  key={trucker.data().truckerId}
                  onClick={() => this.openEditForm(trucker.data())}
                  justIcon
                >
                  <Edit className={classes.icon} />
                </Button>,
              ],
            });
          }
          if (trucker.data().status === 'rejected') {
            rejectedTruckers.push({
              color: colors[2],
              data: [
                trucker.data().name,
                trucker.data().phoneNumber,
                trucker.data().email,
                trucker.data().contactPerson,
              ],
            });
          }
          const truckerResult = trucker.data();
          truckers.push({
            color: colors[flag],
            data: [
              truckerResult.name,
              truckerResult.phoneNumber,
              truckerResult.email,
              truckerResult.contactPerson,
            ],
          });
          if (flag === 0) {
            flag = 1;
          } else {
            flag = 0;
          }
        }
      });
      this.setState({
        truckersData: truckers,
        approvedTruckers,
        pendingTruckers,
        rejectedTruckers,
      });
    });
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable(status) {
    const { classes, activeRole } = this.props;

    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Assignment />
          </CardIcon>
        </CardHeader>
        <CardBody className={classes.customCardContentClass}>
          <MaskedTruckerListView activeRole={activeRole} />
        </CardBody>
      </Card>
    );
  }

  openEditForm(trucker) {
    const { history } = this.props;
    const { activeRole } = this.props;
    history.push({
      pathname: `/${activeRole}/truckers/view/${trucker.truckerId}`,
    });
  }

  /**
   * will redirect to Add Truckers Page
   * @function
   */
  AddTrucker() {
    const { history } = this.props;
    history.push('/sales/truckers/add');
  }

  render() {
    const { classes } = this.props;
    const { view, truckersData, isLoading } = this.state;
    return (
      <>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <p className={`${classes.cardHeader}`}>Truckers</p>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {this.getTabs()}
                </GridItem>
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

Trucker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
});

export default connect(mapStateToProps)(withStyles(TransactionStyle)(Trucker));
