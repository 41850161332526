/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import ArrowBack from '@material-ui/icons/ArrowBack';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import infoIcon from 'assets/img/infoIcon.svg';
import greenTick from 'assets/img/greenCheck.svg';
import Checkbox from '@material-ui/core/Checkbox';

// Fireabse
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Button as MUIButton,
  Radio,
  RadioGroup,
  styled,
  Tooltip,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import moment from 'moment';
import CommentSection from './Section/CommentSection';
import firebase from '../../config/config';
import LRUpload from './Section/LRUpload';
import GSTFileUpload from './Section/GSTFileUpload';
import PANFileUpload from './Section/PANFileUpload';
import AdhaarFileUpload from './Section/AdhaarFileUpload';
import OverAllRating from './Ratings/OverAllRating';
import SecondaryContactsView from './SecondaryContacts/SecondaryContactsView';
import TransporterNewFieldsView from './Section/TransporterNewFields/TransporterNewFieldsView';
import AdhaarBackFileUploader from './Section/AdhaarBackFileUpload';
import TransporterGTA from './Section/TransporterGTA';
import auditLogger from '../../utils/auditLoggerToPG';
import apiCaller from '../../utils/apiCallerOutstanding';
import FeatureFlagToastAlert from '../Components/FeatureFlagToastAlert';
import history from '../../history';
import mapRole from '../../utils/roleMapping';

const contentStyle = { width: '300px' };

const deleteModalButton = {
  width: '45%',
  background: '#52BE56',
  borderRadius: 0,
  color: 'white',
  margin: '5px',
};

const cancelButton = {
  width: '45%',
  background: 'lightgray',
  borderRadius: 0,
  margin: '5px',
};

const panButton = {
  marginTop: '20px',
  marginLeft: '10px',
  height: '35px',
  width: '150px',
  borderRadius: '4px',
  border: '1px solid #8F95B2',
  background: 'transparent',
  color: '#474D66',
  fontWeight: '500',
};

const buttonStyles = {
  background: '#3366FF',
  color: 'white',
  fontSize: '12px',
  textTransform: 'capitalize',
  marginLeft: '20px',
};

const submitButton = {
  background: '#163BAC',
  color: 'white',
};

const branhcesDropDown = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
  borderBottom: '1px solid #E0E0E0',
  background: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  width: '100%',
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    border: '1px solid #ccc',
  },
}));

const { REACT_APP_API_BASE_URL } = process.env;
const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
class AddTransporters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameState: '',

      email: '',
      emailState: '',

      contactPerson: '',

      phoneNumber: '',
      phoneNumberState: '',

      gstNumber: '',
      gstNumberState: '',

      panNumber: '',
      panNumberState: '',

      tanNumber: '',
      tanNumberState: '',

      isLoading: false,

      selectedTransporterData: null,
      componentLoading: false,
      showDetails: false,

      alert: null,
      edit: false,
      blacklisting: false,
      validationMessage: '',
      disableSubmit: false,
      validatingPan: false,
      presentOrgs: [],
      transporterTDSChecked: '',
      transporterGSTChecked: '',
      tdsDeductionChanged: false,
      gstChanged: false,
      isUpdatingTDS: false,
      isUpdatingGST: false,
      featureFlagFiles: '',
      panModal: false,
      panDetailData: {},
      showLoader: false,
      isChecked: false,
      keyAccountModal: false,
      keyAccountBranches: [],
      options: [],
      selectAll: false,
      branchesLoading: false,
      BranchesConfirmationModal: false,
      searchQuery: '',
    };
    this.change = this.change.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleGstCheck = this.handleGstCheck.bind(this);
    this.submitTransporter = this.submitTransporter.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.getTransporterData = this.getTransporterData.bind(this);
    this.validPanDetails = this.validPanDetails.bind(this);
    this.handleKeyAccountBranchesChange = this.handleKeyAccountBranchesChange.bind(
      this
    );
  }

  componentDidMount() {
    this.setState({
      componentLoading: true,
    });
    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(this).scrollIntoView();

    const TransporterId = history.location.pathname.split('/').pop();

    const handleGetTransporterDetails = async id => {
      const docRef = await db.collection('Transporters').doc(id);
      docRef
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();
            this.setState(
              {
                selectedTransporterData: data,
                componentLoading: false,
                showDetails: true,
              },
              () => {
                this.getTransporterData();
                this.auditLog(data);
              }
            );
          }
        })
        .catch(error => error);
    };
    handleGetTransporterDetails(TransporterId);
  }

  getTransporterData() {
    const { selectedTransporterData } = this.state;
    const {
      name,
      contactPerson,
      phoneNumber,
      email,
      gstNumber,
      panNumber,
      tdsDeduction,
      gstBilling,
    } = selectedTransporterData;
    let { tanNumber } = selectedTransporterData;
    if (tanNumber === undefined) tanNumber = '';
    this.getFeatureFlagStatus();
    this.setState({
      name,
      contactPerson,
      phoneNumber,
      email,
      gstNumber,
      panNumber,
      tanNumber,
      transporterGSTChecked: gstBilling ? 'true' : 'false',
    });
    if (tdsDeduction) {
      this.setState({ transporterTDSChecked: tdsDeduction });
    }
  }

  getFeatureFlagStatus() {
    let data;
    db.collection('FeatureFlags')
      .doc('FileUploadsFeature')
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.setState({ featureFlagFiles: data?.transporterFiles });
        }
      });
  }

  auditLog = async data => {
    const userDetails = firebase.auth().currentUser;
    const { activeRole } = this.props;
    const role = mapRole(activeRole);

    const auditBody = {
      type: 'view',
      entity: 'Transporter',
      collection: 'Transporters',
      transporterViewedBy: userDetails.uid,
      email: userDetails.email,
      role: activeRole,
      message: `${userDetails.displayName} with email ${userDetails.email} and role ${activeRole} tried to view ${data.name} transporter with ${data.truckerId} as transporter ID`,
      viewedAt: moment().format('llll'),
    };
    await auditLog(auditBody);
    const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));
    const auditPayload = {
      collection: 'transporter',
      orgId: null,
      data: {},
      message: `${userDetails?.displayName} with email id ${userDetails?.email} viewed a transporter ${data?.name} `,
      systemDetails,
      type: `view Transporter`,
      role: `${this.capitalizeFirstLetter(role)}`,
      eventDateTime: new Date().toISOString(),
      source: 'office',
    };
    auditLogger(auditPayload);
  };

  // eslint-disable-next-line react/sort-comp
  hideAlert() {
    this.setState({
      alert: null,
    });
    const { activeRole } = this.props;
    if (
      activeRole == 'ops' ||
      activeRole == 'finance' ||
      activeRole == 'finance-payable' ||
      activeRole == 'finance-recievable' ||
      activeRole == 'finance-recon'
    ) {
      history.push(`/${activeRole}/transporters`);
    } else if (
      activeRole == 'sales' ||
      activeRole == 'traffic' ||
      activeRole == 'branch-ops'
    ) {
      history.push(`/${activeRole}/transporters/view`);
    }
  }

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  async validatePanNumber(pan) {
    this.setState({ validatingPan: true });

    const endPoint = 'pipe/pan-validate';
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    const body = {
      type: 'transporter',
      pan,
    };
    const response = await axios.post(
      `${REACT_APP_API_BASE_URL}${endPoint}`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const duplicate = response?.data?.Info.pan_match;
    if (duplicate) {
      this.setState({
        panNumberState: 'error',
        validationMessage: 'PAN Number already exists',
        disableSubmit: true,
        validatingPan: false,
      });
    } else {
      this.setState({
        panNumberState: 'success',
        disableSubmit: false,
        validatingPan: false,
      });
    }
  }

  async checkIfMultipleOrgs(number) {
    this.setState({ validatingPhone: true });
    let status = false;
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = `pipe/office-user-validation?phone_number=${number}&type=phone_number_check&source=transporter`;
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const data = response?.data;

      if (data.length > 0) {
        this.setState({ presentOrgs: data });
      } else {
        status = true;
      }
      this.setState({ validatingPhone: false });
    } catch (e) {
      status = true;
      this.setState({ validatingPhone: false });
    }
    return status;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      this.validatePanNumber(value);
    }
    return false;
  }

  // function that verifies if value contains Tan
  static verifyTan(value) {
    const regtan = /(^([a-zA-Z]{4})([0-9]{5})([a-zA-Z]{1})$)/;
    if (regtan.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[6789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'nameViaPan':
        if (this.verifyLength(event, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
            disableSubmit: false,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.checkIfMultipleOrgs(event.target.value);
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'tan':
        if (AddTransporters.verifyTan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      if (this.verifyLength(this.state.name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }

      // Verify Phone Number

      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  handleClickOpenModal(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleCloseModal(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleCheckboxChange = event => {
    this.setState({ isChecked: event.target.checked });
  };

  handleNameChange = event => {
    this.change(event, 'name', 'nameViaPan', 1);
    this.handleCloseModal('panModal');
  };

  async validPanDetails(num) {
    this.setState({
      showLoader: true,
    });
    this.handleClickOpenModal('panModal');
    const userDetails = firebase.auth().currentUser;
    const { Aa: token } = userDetails;

    const endPoint = `pipe/pan-details?pan_number=${num}`;

    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const value = response?.data;
      this.setState({
        showLoader: false,
        panDetailData: value,
      });
    } catch (e) {
      console.log('Error', e);
    }
  }

  showPanDialog() {
    const { classes } = this.props;
    const { panModal, panDetailData, showLoader, isChecked } = this.state;
    const isPanDetailDataEmpty =
      panDetailData?.pan_status === '' ||
      panDetailData?.pan_status === 'INVALID';

    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
        }}
        fullWidth
        maxWidth="sm"
        open={panModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleCloseModal('panModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div className={classes.modalHeaderContent}>
            <h4 className={classes.modalTitle}>PAN Validation Details</h4>
          </div>
        </DialogTitle>

        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {showLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </div>
          ) : isPanDetailDataEmpty ? (
            <div
              style={{
                backgroundColor: '#FDE8E8',
                border: '1px solid #d32f2f',
                color: 'black',
                padding: '10px',
                borderRadius: '5px',
              }}
            >
              <img
                style={{
                  paddingRight: '10px',
                }}
                src={infoIcon}
                alt=""
              />
              Invalid! PAN number or details does not exist
            </div>
          ) : (
            <div>
              {/* Render the PAN detail data */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Verified Name</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.verified_name}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Name on PAN</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.name_pan_card}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>DOB</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_date_of_birth}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>PAN Type</p>
                <p style={{ fontWeight: 'bold' }}>{panDetailData?.pan_type}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>PAN Status</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_status}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Aadhaar Linked</p>
                <p style={{ fontWeight: 'bold', color: 'green' }}>
                  {panDetailData?.pan_aadhaar_linked && (
                    <img src={greenTick} alt="icon" />
                  )}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Gender</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_gender}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>PAN Email</p>
                <p style={{ fontWeight: 'bold' }}>{panDetailData?.pan_email}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Address State</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_address?.city}
                  {panDetailData?.pan_address?.state}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Address Pincode</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_address?.pincode}
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={isChecked}
                  onChange={this.handleCheckboxChange}
                />
                <p style={{ margin: 0 }}>Update Transporter Name as per PAN</p>
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.handleCloseModal('panModal')}>
            Close
          </Button>
          <Button
            style={submitButton}
            disabled={!isChecked}
            onClick={() => this.handleNameChange(panDetailData?.name_pan_card)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  async blacklistTrucker(id) {
    const { activeRole } = this.props;
    this.setState({ blacklisting: true });
    const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));

    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = 'pipe/add-blacklist';
      const body = {
        id,
        type: 'transporter',
        role: `is${this.capitalizeFirstLetter(
          activeRole === 'branch-ops' ? 'branchOps' : activeRole.split('is')[0]
        )}`,
      };
      await axios.post(`${REACT_APP_API_BASE_URL}${endPoint}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.setState({ blacklisting: false });
      const auditPayload = {
        collection: 'transporter',
        orgId: id,
        data: body,
        message: `${userDetails?.displayName} with email id ${userDetails?.email} blacklisted a transporter`,
        systemDetails,
        type: `Blacklist Transporter`,
        role: `${this.capitalizeFirstLetter(
          activeRole === 'branch-ops' ? 'branchOps' : activeRole?.split('is')[0]
        )}`,
        eventDateTime: new Date().toISOString(),
        source: 'office',
      };
      auditLogger(auditPayload);
      swal(
        'Blacklisted Transporter!',
        'you have blacklisted transporter successfully!',
        'success'
      );
    } catch (e) {
      this.setState({ blacklisting: false });
    }
  }

  capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }

  submitPendingEdit() {
    const {
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      panNumber,
      tanNumber,
    } = this.state;
    const {
      userDetails,
      // systemDetails,
      activeRole,
    } = this.props;
    const { selectedTransporterData } = this.state;
    const transporterEditData = {
      ...selectedTransporterData,
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      panNumber,
      tanNumber,
      editedBy: userDetails.uid,
      editedByName: userDetails.name,
      modifiedAt: new Date(),
    };
    const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));
    const transporterRef = db.collection(`Transporters`);
    transporterRef
      .doc(selectedTransporterData.transporterId)
      .update({
        transporterEditData,
        editPending: true,
        editedBy: userDetails.name,
      })
      .then(userDoc => {
        const auditBody = {
          data: {
            name,
            email,
            contactPerson,
            phoneNumber,
            gstNumber,
            panNumber,
            tanNumber,
          },
          collection: 'Transporters',
          editedBy: userDetails.name,
          systemDetails,
          type: 'Edit Transporter',
          message: `${userDetails.email} edited Transporter with name ${name}`,
        };
        auditLog(auditBody);
        const auditPayload = {
          collection: 'transporter',
          orgId: selectedTransporterData.transporterId,
          data: {
            name,
            email,
            contactPerson,
            phoneNumber,
            gstNumber,
            panNumber,
            tanNumber,
            editedBy: userDetails.uid,
            editedByName: userDetails.name,
            modifiedAt: new Date(),
          },
          message: `${userDetails?.name} with email id ${userDetails?.email} editted a transporter `,
          systemDetails,
          type: `Edit Transporter`,
          role: `${this.capitalizeFirstLetter(
            activeRole === 'branch-ops'
              ? 'branchOps'
              : activeRole?.split('is')[0]
          )}`,
          eventDateTime: new Date().toISOString(),
          source: 'office',
        };
        auditLogger(auditPayload);
        this.clearForm();
        this.setState({ isLoading: false });

        // Updated Succeessfully
      })
      .catch(err => {
        const errorBody = {
          message: `${userDetails.email} tried to edit Transporter with name ${name} but FAILED`,
          status: 'fail',
          error: err,
          updatedBy: userDetails.uid,
          errorMessage: err.message,
          collection: 'Transporters',
          systemDetails,
        };
        auditLog(errorBody);
      });
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  submitTransporter() {
    const self = this;
    this.setState({
      isLoading: true,
    });
    const {
      userDetails,
      // systemDetails,
      activeRole,
    } = this.props;
    const { selectedTransporterData } = this.state;
    const {
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      panNumber,
      tanNumber,
      transporterTDSChecked,
      transporterGSTChecked,
    } = this.state;
    const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));
    this.validateAllFields()
      .then(
        success => {
          if (success) {
            if (activeRole === 'sales' || activeRole === 'traffic')
              this.submitPendingEdit();
            else {
              const transporterRef = db.collection(`Transporters`);
              transporterRef
                .doc(selectedTransporterData.transporterId)
                .update({
                  name,
                  email,
                  contactPerson,
                  phoneNumber,
                  gstNumber,
                  panNumber,
                  tanNumber,
                  editedBy: userDetails.uid,
                  editedByName: userDetails.name,
                  modifiedAt: new Date(),
                  tdsDeduction:
                    transporterTDSChecked === 'none' ||
                    transporterTDSChecked === ''
                      ? null
                      : transporterTDSChecked,
                  gstBilling: transporterGSTChecked,
                })
                .then(userDoc => {
                  const auditBody = {
                    data: {
                      name,
                      email,
                      contactPerson,
                      phoneNumber,
                      gstNumber,
                      panNumber,
                      tanNumber,
                    },
                    collection: 'Transporters',
                    updatedBy: userDetails.uid,
                    systemDetails,
                    type: 'Edit Transporter',
                    message: `${userDetails.email} edited Transporter with name ${name}`,
                  };
                  auditLog(auditBody);

                  const auditPayload = {
                    collection: 'transporter',
                    orgId: selectedTransporterData.transporterId,
                    data: {
                      name,
                      email,
                      contactPerson,
                      phoneNumber,
                      gstNumber,
                      panNumber,
                      tanNumber,
                      editedBy: userDetails.uid,
                      editedByName: userDetails.name,
                      modifiedAt: new Date(),
                    },
                    message: `${userDetails?.name} with email id ${userDetails?.email} editted a transporter with id ${selectedTransporterData.transporterId}`,
                    systemDetails,
                    type: `Edit Transporter`,
                    role: `${this.capitalizeFirstLetter(
                      activeRole === 'branch-ops'
                        ? 'branchOps'
                        : activeRole?.split('is')[0]
                    )}`,
                    eventDateTime: new Date().toISOString(),
                    source: 'office',
                  };
                  auditLogger(auditPayload);
                  // Updated Succeessfully
                  this.clearForm();
                  this.setState({ isLoading: false });
                })
                .catch(err => {
                  const errorBody = {
                    message: `${userDetails.email} tried to edit Transporter with name ${name} but FAILED`,
                    status: 'fail',
                    error: err,
                    updatedBy: userDetails.uid,
                    errorMessage: err.message,
                    collection: 'Transporters',
                    systemDetails,
                  };
                  auditLog(errorBody);
                });
            }
          }
        },
        err => {
          console.log(err);
          self.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        console.log(err);
        self.setState({
          isLoading: false,
        });
      });
  }

  handleCheck = event => {
    // setValue(event.target.value);
    this.setState({
      transporterTDSChecked: event.target.value,
      tdsDeductionChanged: true,
    });
  };

  handleGstCheck = event => {
    // setValue(event.target.value);
    this.setState({
      transporterGSTChecked: event.target.value,
      gstChanged: true,
    });
  };

  handleSubmitTDS = () => {
    this.setState({ isUpdatingTDS: true });
    const { activeRole } = this.props;
    const { transporterTDSChecked } = this.state;
    const { userDetails, systemDetails } = this.props;
    const { selectedTransporterData } = this.state;
    const transporterRef = db.collection(`Transporters`);
    transporterRef
      .doc(selectedTransporterData.transporterId)
      .update({
        editedBy: userDetails.uid,
        editedByName: userDetails.name,
        modifiedAt: new Date(),
        tdsDeduction:
          transporterTDSChecked === '' ? null : transporterTDSChecked,
      })
      .then(userDoc => {
        const auditBody = {
          data: {
            editedBy: userDetails.uid,
            editedByName: userDetails.name,
            modifiedAt: new Date(),
            tdsDeduction:
              transporterTDSChecked === 'none' || transporterTDSChecked === ''
                ? null
                : transporterTDSChecked,
          },
          collection: 'Transporters',
          updatedBy: userDetails.uid,
          systemDetails,
          type: 'Edit Transporter',
          message: `${userDetails.email} edited Transporter with name ${selectedTransporterData.name}`,
        };
        auditLog(auditBody);
        const auditPayload = {
          collection: 'Transactions',
          orgId: selectedTransporterData?.transporterId,
          data: {
            editedBy: userDetails.uid,
            editedByName: userDetails.name,
            modifiedAt: new Date(),
            tdsDeduction:
              transporterTDSChecked === 'none' || transporterTDSChecked === ''
                ? null
                : transporterTDSChecked,
          },
          message: `${userDetails.email} edited Transporter TDS Decution for transporter with name ${selectedTransporterData.name}`,
          systemDetails,
          type: 'TDS Deduction Update',
          role: `${this.capitalizeFirstLetter(
            activeRole === 'branch-ops'
              ? 'branchOps'
              : activeRole?.split('is')[0]
          )}`,
          eventDateTime: new Date().toISOString(),
          source: 'office',
        };
        auditLogger(auditPayload);
        // Updated Succeessfully
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: 'block', marginTop: '-100px' }}
              title="Good job!"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
            >
              TDS Deduction updated successfully
            </SweetAlert>
          ),
        });
        this.setState({ isUpdatingTDS: false });
      })
      .catch(err => {
        const errorBody = {
          message: `${userDetails.email} tried to edit Transporter with name ${selectedTransporterData.name} but FAILED`,
          status: 'fail',
          error: err,
          updatedBy: userDetails.uid,
          errorMessage: err.message,
          collection: 'Transporters',
          systemDetails,
        };
        auditLog(errorBody);
        this.setState({ isUpdatingTDS: false });
      });
  };

  handleSubmitGST = () => {
    this.setState({ isUpdatingGST: true });
    const { activeRole } = this.props;
    const { transporterGSTChecked } = this.state;
    const { userDetails, systemDetails } = this.props;
    const { selectedTransporterData } = this.state;
    const transporterRef = db.collection(`Transporters`);
    transporterRef
      .doc(selectedTransporterData.transporterId)
      .update({
        editedBy: userDetails.uid,
        editedByName: userDetails.name,
        modifiedAt: new Date(),
        gstBilling: transporterGSTChecked,
      })
      .then(userDoc => {
        const auditBody = {
          data: {
            editedBy: userDetails.uid,
            editedByName: userDetails.name,
            modifiedAt: new Date(),
            gstBilling: transporterGSTChecked,
          },
          collection: 'Transporters',
          updatedBy: userDetails.uid,
          systemDetails,
          type: 'Edit Transporter',
          message: `${userDetails.email} edited Transporter with name ${selectedTransporterData.name}`,
        };
        auditLog(auditBody);
        const auditPayload = {
          collection: 'Transactions',
          orgId: selectedTransporterData?.transporterId,
          data: {
            editedBy: userDetails.uid,
            editedByName: userDetails.name,
            modifiedAt: new Date(),
            gstBilling: transporterGSTChecked,
          },
          message: `${userDetails.email} edited Transporter GST Billing for transporter with name ${selectedTransporterData.name}`,
          systemDetails,
          type: 'GST Billing Update',
          role: `${this.capitalizeFirstLetter(
            activeRole === 'branch-ops'
              ? 'branchOps'
              : activeRole?.split('is')[0]
          )}`,
          eventDateTime: new Date().toISOString(),
          source: 'office',
        };
        auditLogger(auditPayload);
        // Updated Succeessfully
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: 'block', marginTop: '-100px' }}
              title="Good job!"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
            >
              GST Billing updated successfully
            </SweetAlert>
          ),
        });
        this.setState({ isUpdatingGST: false });
      })
      .catch(err => {
        const errorBody = {
          message: `${userDetails.email} tried to edit Transporter with name ${selectedTransporterData.name} but FAILED`,
          status: 'fail',
          error: err,
          updatedBy: userDetails.uid,
          errorMessage: err.message,
          collection: 'Transporters',
          systemDetails,
        };
        auditLog(errorBody);
        this.setState({ isUpdatingGST: false });
      });
  };

  handleKeyAccountBranchesChange = event => {
    const { keyAccountModal } = this.state;
    this.setState({
      branchesLoading: true,
      keyAccountModal: !keyAccountModal,
    });
    this.getBranches();
  };

  getBranches = async () => {
    const { selectedTransporterData } = this.state;
    const branches = selectedTransporterData.keyAccountBranches || [];
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = `utils/branches`;
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const data = response?.data?.body;
      const options = data?.map(branch => ({
        label: branch,
        selected: branches.includes(branch),
      }));

      const selectAll =
        branches.length > 0 && data.every(branch => branches.includes(branch));
      this.setState({ options, branchesLoading: false, selectAll });
    } catch (e) {}
  };

  handleSelectAll = () => {
    const { options, selectAll } = this.state;
    const updatedOptions = options.map(option => ({
      ...option,
      selected: !selectAll,
    }));
    const selectedBranches = updatedOptions
      .filter(option => option.selected)
      .map(option => option.label);

    this.setState({
      options: updatedOptions,
      selectAll: !selectAll,
      keyAccountBranches: selectedBranches,
    });
  };

  handleOptionChange = label => {
    const { options } = this.state;
    const updatedOptions = options.map(option =>
      option.label === label
        ? { ...option, selected: !option.selected }
        : option
    );

    const selectedBranches = updatedOptions
      .filter(option => option.selected)
      .map(option => option.label);

    this.setState({
      options: updatedOptions,
      selectAll: updatedOptions.every(option => option.selected),
      keyAccountBranches: selectedBranches,
    });
  };

  postKeyAccountBranches = async () => {
    this.setState({
      BranchesConfirmationModal: false,
      branchesLoading: true,
    });
    const { selectedTransporterData, keyAccountBranches } = this.state;
    const { activeRole } = this.props;
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    const currentRole = activeRole === 'branch-ops' ? 'BranchOps' : activeRole;
    const currentActiveRole = `is${currentRole
      .charAt(0)
      .toUpperCase()}${currentRole.slice(1)}`;
    const body = {
      role: currentActiveRole,
      id: selectedTransporterData.transporterId,
      type: 'transporter',
      key_account_branches: keyAccountBranches,
    };
    try {
      const endPoint = `pipe/key-account/add-branches`;
      const response = await apiCaller(
        endPoint,
        body,
        `Bearer ${token}`,
        'nh-base',
        'post'
      );
      const data = response?.data;
      this.setState({
        branchesLoading: false,
        keyAccountModal: false,
        searchQuery: '',
      });
      swal('Success!', `${data?.message}`);
      const transporterRef = db
        .collection('Transporters')
        .doc(selectedTransporterData.transporterId);
      const doc = await transporterRef.get();

      if (doc.exists) {
        const updatedData = doc.data();
        this.setState({
          selectedTransporterData: updatedData,
        });
      }
    } catch (e) {
      this.setState({ branchesLoading: false });
      swal(
        'Oops!',
        'Failed to add key Acount Branches, Please Try Again',
        'error'
      );
    }
  };

  showKeyAccountDialog() {
    const {
      options,
      selectAll,
      branchesLoading,
      BranchesConfirmationModal,
      searchQuery,
    } = this.state;

    const filteredOptions = options?.filter(option =>
      option.label.toUpperCase().includes(searchQuery.toUpperCase())
    );

    return (
      <div
        style={{
          position: 'absolute',
          left: '-200px',
          background: '#fff',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          padding: '16px',
          zIndex: 1000,
          width: '275px',
        }}
      >
        {branchesLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <p style={{ color: '#163BAC', fontWeight: 500 }}>
                Select the branches where Key Accounts are associated:
              </p>
            </div>
            <div style={{ marginBottom: '12px' }}>
              <input
                type="text"
                placeholder="Search branches"
                value={searchQuery}
                onChange={e => this.setState({ searchQuery: e.target.value })}
                style={{
                  width: '100%',
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid #E0E0E0',
                  fontSize: '14px',
                }}
              />
            </div>
            <button
              style={branhcesDropDown}
              onClick={this.handleSelectAll}
              type="button"
            >
              <input
                type="checkbox"
                checked={selectAll}
                style={{
                  marginRight: '8px',
                  accentColor: '#0039CB',
                }}
              />
              <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                Select all
              </span>
            </button>
            <div
              style={{
                maxHeight: '250px',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
              }}
            >
              {filteredOptions && filteredOptions?.length > 0 ? (
                filteredOptions
                  .slice()
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((option, index) => (
                    <button
                      key={`${option.label}-${index}`}
                      style={branhcesDropDown}
                      type="button"
                      onClick={() => this.handleOptionChange(option.label)}
                    >
                      <input
                        type="checkbox"
                        checked={option.selected}
                        style={{
                          marginRight: '8px',
                          accentColor: '#0039CB',
                        }}
                      />
                      <span style={{ fontSize: '14px', color: '#333' }}>
                        {option.label}
                      </span>
                    </button>
                  ))
              ) : (
                <div style={{ textAlign: 'center', color: '#666' }}>
                  No branches match your search.
                </div>
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <Button
                size="sm"
                color="danger"
                fullWidth
                onClick={() => this.handleCloseModal('keyAccountModal')}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                color="behance"
                fullWidth
                onClick={() =>
                  this.handleClickOpenModal('BranchesConfirmationModal')
                }
              >
                Confirm
              </Button>
            </div>
          </>
        )}
        <Dialog open={BranchesConfirmationModal} fullWidth maxWidth="xs">
          <DialogTitle>Are You Sure?</DialogTitle>
          <DialogContent>
            <p>Are you sure to proceed with the change?</p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleCloseModal('BranchesConfirmationModal')}
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.postKeyAccountBranches()}
              color="success"
            >
              Yes, Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
        >
          Transporter Edited Succeessfully
        </SweetAlert>
      ),
    });
    const self = this;
    self.setState({
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      contactPersonState: '',
      phoneNumber: '',
      phoneNumberState: '',
      creditLimit: '',
      creditLimitState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      tanNumber: '',
      tanNumberState: '',
      city: '',
      cityState: '',
      state: '',
      stateState: '',
      postalCode: '',
      postalCodeState: '',
    });
  }

  render() {
    const { classes, userDetails, activeRole } = this.props;
    const { featureFlagFiles, componentLoading, showDetails } = this.state;
    const isButtonDisabled =
      activeRole !== 'ops' &&
      activeRole !== 'branch-ops' &&
      activeRole !== 'admin' &&
      !(activeRole === 'sales' && userDetails.isSalesManager);

    return (
      <div>
        {featureFlagFiles === 1 && <FeatureFlagToastAlert />}
        {componentLoading ? (
          <GridContainer justify="center">
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={3}
            />
          </GridContainer>
        ) : (
          showDetails && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {this.state.alert}
                <Card>
                  <CardHeader
                    className={`${classes.cardHeader}`}
                    color="warning"
                  >
                    <CardIcon
                      color="primary"
                      className={`${classes.cardHeader}`}
                      onClick={() => this.hideAlert()}
                    >
                      <ArrowBack />
                    </CardIcon>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h4 className={classes.cardIconTitle}>
                        Add Transporter
                        <small />
                      </h4>
                      {this.state.blacklisting ? (
                        <CircularProgress />
                      ) : activeRole === 'ops' ? (
                        <Popup
                          trigger={
                            this.state.blacklisting ? (
                              <CircularProgress
                                style={{ color: '#FCD21F', margin: '5px' }}
                                size={30}
                                thickness={8}
                              />
                            ) : (
                              <Button
                                style={{
                                  background: 'black',
                                  color: '#FCD21F',
                                  fontWeight: 'bold',
                                }}
                              >
                                Blacklist
                              </Button>
                            )
                          }
                          {...{
                            contentStyle,
                          }}
                          modal
                        >
                          {close => (
                            <div
                              style={{
                                padding: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <HighlightOffIcon
                                fontSize="large"
                                color="error"
                              />

                              <h4 style={{ fontWeight: 'bold' }}>
                                Are You Sure?
                              </h4>
                              <p>
                                If you proceed, you will not be able to recover
                                it
                              </p>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <Button onClick={close} style={cancelButton}>
                                  No
                                </Button>

                                <Button
                                  onClick={() => {
                                    close();
                                    this.blacklistTrucker(
                                      this.state.selectedTransporterData
                                        .transporterId
                                    );
                                  }}
                                  style={deleteModalButton}
                                >
                                  Yes
                                </Button>
                              </div>
                            </div>
                          )}
                        </Popup>
                      ) : null}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{
                          display: 'flex',
                          alignItems: 'baseline',
                          gap: '20px',
                        }}
                      >
                        <CustomInput
                          labelText="Transporter Name *"
                          id="username"
                          formControlProps={{ fullWidth: true }}
                          success={this.state.nameState === 'success'}
                          error={this.state.nameState === 'error'}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'name', 'length', 1),
                            type: 'length',
                            value: this.state.name,
                            readOnly:
                              activeRole === 'finance' ||
                              activeRole === 'finance-payable' ||
                              activeRole === 'finance-recievable' ||
                              activeRole === 'finance-recon',
                          }}
                        />
                        <div>
                          <Button
                            color={isButtonDisabled ? '' : 'behance'}
                            size="sm"
                            fullWidth
                            disabled={isButtonDisabled}
                            onClick={this.handleKeyAccountBranchesChange}
                          >
                            Key Account
                          </Button>
                          <div>
                            {this.state.selectedTransporterData
                              ?.keyAccountBranches?.length > 0 && (
                              <CustomTooltip
                                title={
                                  <div>
                                    {this.state.selectedTransporterData.keyAccountBranches.map(
                                      (branch, index) => (
                                        <div key={branch}>
                                          {branch}
                                          {index <
                                            this.state.selectedTransporterData
                                              .keyAccountBranches?.length -
                                              1 && (
                                            <hr
                                              style={{
                                                margin: '4px 0',
                                                borderColor: '#ccc',
                                                borderStyle: 'solid',
                                              }}
                                            />
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                }
                              >
                                <Typography
                                  variant="caption"
                                  style={{
                                    backgroundColor: '#EEECEC',
                                    padding: '5px',
                                    whiteSpace: 'nowrap',
                                    borderRadius: '5px',
                                  }}
                                >
                                  {
                                    this.state.selectedTransporterData
                                      ?.keyAccountBranches[0]
                                  }
                                  {this.state.selectedTransporterData
                                    ?.keyAccountBranches?.length > 1 &&
                                    ` +${this.state.selectedTransporterData
                                      .keyAccountBranches?.length - 1} more`}
                                </Typography>
                              </CustomTooltip>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          {this.state.keyAccountModal &&
                            this.showKeyAccountDialog()}
                        </div>
                      </GridItem>
                      {activeRole !== 'finance' &&
                        activeRole !== 'finance-payable' &&
                        activeRole !== 'finance-recievable' &&
                        activeRole !== 'finance-recon' && (
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Contact Person "
                              id="contact-person"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                onChange: event =>
                                  this.change(
                                    event,
                                    'contactPerson',
                                    'length',
                                    0
                                  ),
                                type: 'length',
                                value: this.state.contactPerson,
                              }}
                            />
                          </GridItem>
                        )}
                    </GridContainer>
                    {activeRole !== 'finance' &&
                      activeRole !== 'finance-payable' &&
                      activeRole !== 'finance-recievable' &&
                      activeRole !== 'finance-recon' && (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              {!this.state.edit ? (
                                <CustomInput
                                  labelText="Phone Number *"
                                  id="phone-number"
                                  formControlProps={{ fullWidth: true }}
                                  inputProps={{
                                    readOnly: true,
                                    onChange: event =>
                                      this.change(
                                        event,
                                        'phoneNumber',
                                        'phone'
                                      ),
                                    type: 'phone',
                                    value:
                                      activeRole == 'ops' ||
                                      activeRole == 'admin'
                                        ? this.state.phoneNumber
                                        : `******${String(
                                            this.state.phoneNumber
                                          ).slice(-4)}`,
                                  }}
                                  success={
                                    this.state.phoneNumberState === 'success'
                                  }
                                  error={
                                    this.state.phoneNumberState === 'error'
                                  }
                                />
                              ) : (
                                <div
                                  style={{ marginTop: '20px', width: '100%' }}
                                >
                                  <TextField
                                    onChange={event => {
                                      const regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
                                      if (regex.test(event.target.value)) {
                                        this.checkIfMultipleOrgs(
                                          event.target.value
                                        );
                                        this.setState({
                                          phoneNumber: event.target.value,
                                          phoneNumberState: 'success',
                                        });
                                      } else
                                        this.setState({
                                          phoneNumberState: 'error',
                                        });
                                    }}
                                    label="Phone Number *"
                                    fullWidth
                                    error={
                                      this.state.phoneNumberState === 'error'
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            <div>
                              {this.state.presentOrgs?.length > 0 ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',

                                    flexWrap: 'wrap',
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    gutterBottom
                                    color="primary"
                                  >
                                    Org already exists with Phone Number &nbsp;
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    gutterBottom
                                    color="primary"
                                  >
                                    (
                                  </Typography>
                                  {this.state.presentOrgs?.map((org, index) => (
                                    <Typography
                                      variant="caption"
                                      gutterBottom
                                      color="primary"
                                      style={{
                                        marginRight: '5px',
                                        textDecoration: org?.blacklist
                                          ? 'line-through'
                                          : 'none',
                                      }}
                                    >
                                      {org.company_name},
                                    </Typography>
                                  ))}
                                  <Typography
                                    variant="caption"
                                    gutterBottom
                                    color="primary"
                                  >
                                    )
                                  </Typography>
                                </div>
                              ) : null}
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              success={this.state.emailState === 'success'}
                              error={this.state.emailState === 'error'}
                              labelText="Email address "
                              id="email-address"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                onChange: event =>
                                  this.change(event, 'email', 'email'),
                                type: 'email',
                                value: this.state.email,
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      )}
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="GST Number "
                          id="gst-number"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'gstNumber', 'gst'),
                            type: 'gst',
                            value: this.state.gstNumber,
                            readOnly:
                              activeRole === 'finance' ||
                              activeRole === 'finance-payable' ||
                              activeRole === 'finance-recievable' ||
                              activeRole === 'finance-recon',
                          }}
                          success={this.state.gstNumberState === 'success'}
                          error={this.state.gstNumberState === 'error'}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <CustomInput
                            labelText="PAN Number"
                            id="pan-number"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'panNumber', 'pan'),
                              type: 'pan',
                              value: this.state.panNumber,
                              readOnly:
                                activeRole === 'finance' ||
                                activeRole === 'finance-payable' ||
                                activeRole === 'finance-recievable' ||
                                activeRole === 'finance-recon',
                            }}
                            success={this.state.panNumberState === 'success'}
                            error={this.state.panNumberState === 'error'}
                          />
                          {this.state.validatingPan ? (
                            <CircularProgress />
                          ) : null}
                          <Button
                            style={panButton}
                            disabled={!this.state.panNumber}
                            onClick={() =>
                              this.validPanDetails(this.state.panNumber)
                            }
                          >
                            Pan Valid Details
                          </Button>
                        </div>

                        {this.state.panNumberState === 'error' ? (
                          <p style={{ color: 'red' }}>
                            {this.state.validationMessage}
                          </p>
                        ) : (
                          ''
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="TAN Number "
                          id="tan-number"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'tanNumber', 'tan'),
                            type: 'tan',
                            value: this.state.tanNumber,
                            readOnly:
                              activeRole === 'finance' ||
                              activeRole === 'finance-payable' ||
                              activeRole === 'finance-recievable' ||
                              activeRole === 'finance-recon',
                          }}
                          success={this.state.tanNumberState === 'success'}
                          error={this.state.tanNumberState === 'error'}
                        />
                      </GridItem>
                      {(this.props.activeRole === 'ops' ||
                        this.props.activeRole === 'sales' ||
                        this.props.activeRole === 'traffic' ||
                        this.props.activeRole === 'finance' ||
                        this.props.activeRole === 'finance-payable' ||
                        this.props.activeRole === 'finance-recievable' ||
                        this.props.activeRole === 'finance-recon' ||
                        this.props.activeRole === 'branch-ops') && (
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            style={{ display: 'flex', alignItems: 'baseline' }}
                          >
                            <FormControl component="fieldset">
                              <FormLabel component="legend">
                                TDS Deduction
                              </FormLabel>
                              <RadioGroup
                                aria-label="TDS Deduction"
                                name="TDS Deduction"
                                value={this.state.transporterTDSChecked}
                                onChange={this.handleCheck}
                                row
                              >
                                <FormControlLabel
                                  value="lobb"
                                  control={<Radio size="small" />}
                                  disabled={
                                    activeRole === 'finance' ||
                                    activeRole === 'finance-payable' ||
                                    activeRole === 'finance-recievable' ||
                                    activeRole === 'finance-recon'
                                  }
                                  label={
                                    <Typography variant="caption">
                                      Deducted to Lobb
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  value="trucker"
                                  control={<Radio size="small" />}
                                  disabled={
                                    activeRole === 'finance' ||
                                    activeRole === 'finance-payable' ||
                                    activeRole === 'finance-recievable' ||
                                    activeRole === 'finance-recon'
                                  }
                                  label={
                                    <Typography variant="caption">
                                      Deducted to Trucker
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  value="none"
                                  control={<Radio size="small" />}
                                  disabled={
                                    activeRole === 'finance' ||
                                    activeRole === 'finance-payable' ||
                                    activeRole === 'finance-recievable' ||
                                    activeRole === 'finance-recon'
                                  }
                                  label={
                                    <Typography variant="caption">
                                      Deducted to None
                                    </Typography>
                                  }
                                />
                                {this.state.tdsDeductionChanged && (
                                  <MUIButton
                                    variant="contained"
                                    style={buttonStyles}
                                    size="small"
                                    onClick={this.handleSubmitTDS}
                                  >
                                    {this.state.isUpdatingTDS ? (
                                      <CircularProgress
                                        size={20}
                                        color="white"
                                      />
                                    ) : (
                                      'Submit'
                                    )}
                                  </MUIButton>
                                )}
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </GridItem>
                      )}
                      {(this.props.activeRole === 'ops' ||
                        this.props.activeRole === 'sales' ||
                        this.props.activeRole === 'traffic' ||
                        this.props.activeRole === 'finance' ||
                        this.props.activeRole === 'finance-payable' ||
                        this.props.activeRole === 'finance-recievable' ||
                        this.props.activeRole === 'finance-recon' ||
                        this.props.activeRole === 'branch-ops') && (
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            style={{ display: 'flex', alignItems: 'baseline' }}
                          >
                            <FormControl component="fieldset">
                              <FormLabel component="legend">
                                GST Billing
                              </FormLabel>
                              <RadioGroup
                                aria-label="GST Billing"
                                name="GST Billing"
                                value={this.state.transporterGSTChecked}
                                onChange={this.handleGstCheck}
                                row
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio size="small" />}
                                  disabled={
                                    activeRole === 'finance' ||
                                    activeRole === 'finance-payable' ||
                                    activeRole === 'finance-recievable' ||
                                    activeRole === 'finance-recon'
                                  }
                                  label={
                                    <Typography variant="caption">
                                      Yes
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio size="small" />}
                                  disabled={
                                    activeRole === 'finance' ||
                                    activeRole === 'finance-payable' ||
                                    activeRole === 'finance-recievable' ||
                                    activeRole === 'finance-recon'
                                  }
                                  label={
                                    <Typography variant="caption">
                                      No
                                    </Typography>
                                  }
                                />
                                {this.state.gstChanged && (
                                  <MUIButton
                                    variant="contained"
                                    style={buttonStyles}
                                    size="small"
                                    onClick={this.handleSubmitGST}
                                  >
                                    {this.state.isUpdatingGST ? (
                                      <CircularProgress
                                        size={20}
                                        color="white"
                                      />
                                    ) : (
                                      'Submit'
                                    )}
                                  </MUIButton>
                                )}
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </GridItem>
                      )}

                      <GridItem xs={12} sm={12} md={12}>
                        <TransporterNewFieldsView
                          activeRole={this.props.activeRole}
                          selectedTransporterData={
                            this.state.selectedTransporterData
                          }
                        />
                      </GridItem>
                      {activeRole !== 'finance' &&
                        activeRole !== 'finance-payable' &&
                        activeRole !== 'finance-recievable' &&
                        activeRole !== 'finance-recon' && (
                          <GridItem xs={12} sm={12} md={12}>
                            <SecondaryContactsView
                              classes={classes}
                              transporterData={
                                this.state.selectedTransporterData
                              }
                              activeRole={this.props.activeRole}
                              handleContacts={value =>
                                this.handleContacts(value)
                              }
                            />
                          </GridItem>
                        )}
                      <GridItem xs={12} sm={12} md={6}>
                        <LRUpload
                          transporterData={this.state.selectedTransporterData}
                          activeRole={this.props.activeRole}
                          transporterId={
                            this.state.selectedTransporterData.transporterId
                          }
                          featureFlagFiles={featureFlagFiles}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <GSTFileUpload
                          transporterData={this.state.selectedTransporterData}
                          activeRole={this.props.activeRole}
                          transporterId={
                            this.state.selectedTransporterData.transporterId
                          }
                          featureFlagFiles={featureFlagFiles}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <PANFileUpload
                          transporterData={this.state.selectedTransporterData}
                          activeRole={this.props.activeRole}
                          transporterId={
                            this.state.selectedTransporterData.transporterId
                          }
                          featureFlagFiles={featureFlagFiles}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <AdhaarFileUpload
                          transporterData={this.state.selectedTransporterData}
                          activeRole={this.props.activeRole}
                          transporterId={
                            this.state.selectedTransporterData.transporterId
                          }
                          featureFlagFiles={featureFlagFiles}
                        />
                      </GridItem>
                      {featureFlagFiles === 2 && (
                        <GridItem xs={12} sm={12} md={6}>
                          <AdhaarBackFileUploader
                            transporterData={this.state.selectedTransporterData}
                            activeRole={this.props.activeRole}
                            transporterId={
                              this.state.selectedTransporterData.transporterId
                            }
                          />
                        </GridItem>
                      )}
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        {this.showPanDialog()}
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <GridItem xs={12} sm={12} md={6}>
                        <GridContainer direction="column">
                          <GridItem xs={12} sm={12} md={12}>
                            <TransporterGTA
                              transporterData={
                                this.state.selectedTransporterData
                              }
                              activeRole={this.props.activeRole}
                              stylesClass={this.props}
                              transporterId={
                                this.state.selectedTransporterData.transporterId
                              }
                              featureFlagFiles={featureFlagFiles}
                            />
                          </GridItem>
                          {activeRole !== 'finance' &&
                            activeRole !== 'finance-payable' &&
                            activeRole !== 'finance-recievable' &&
                            activeRole !== 'finance-recon' && (
                              <GridItem xs={12} sm={12} md={12}>
                                <OverAllRating
                                  transporterData={
                                    this.state.selectedTransporterData
                                  }
                                  activeRole={this.props.activeRole}
                                  transporterId={
                                    this.state.selectedTransporterData
                                      .transporterId
                                  }
                                />
                              </GridItem>
                            )}
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CommentSection
                          transporterData={this.state.selectedTransporterData}
                          activeRole={this.props.activeRole}
                        />
                      </GridItem>
                    </GridContainer>
                    {activeRole !== 'finance' &&
                      activeRole !== 'finance-payable' &&
                      activeRole !== 'finance-recievable' &&
                      activeRole !== 'finance-recon' &&
                      (this.state.isLoading ? (
                        <CircularProgress
                          className={classes.progress}
                          style={{ color: purple[500] }}
                          thickness={7}
                        />
                      ) : (
                        <Button
                          color="warning"
                          onClick={this.submitTransporter}
                          className={classes.updateProfileButton}
                          disabled={
                            this.state.disableSubmit || featureFlagFiles === 1
                          }
                        >
                          Submit
                        </Button>
                      ))}
                    <Clearfix />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )
        )}
      </div>
    );
  }
}
AddTransporters.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(
  withStyles(userProfileStyles)(AddTransporters)
);
